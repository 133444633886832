import React, { MouseEvent, useState, useRef, useEffect } from 'react'
import { motion, useMotionTemplate, useMotionValue } from "framer-motion";
import chatbot from '../../images/chatbot-landing.png'
import ArrowSvg from '../../svg/rightArrow.svg'
import { Link } from 'react-router-dom';

function SectionE() {
    let mouseX = useMotionValue(0)
    let mouseY = useMotionValue(0);
    const cardRef = useRef(null);



    function handleMouseMove({
        currentTarget,
        clientX,
        clientY,
    }: MouseEvent) {
        let { left, top } = currentTarget.getBoundingClientRect();
        mouseX.set(clientX - left);
        mouseY.set(clientY - top);
    }



    useEffect(() => {

        const card = cardRef.current;
        const handleMouseMove = (event) => {
            const { clientX, clientY } = event;

            if (card) {
                const rect = card.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;
                const offsetY = (clientY - centerY) / 100;
                const offsetX = (clientX - centerX) / 1000;
                card.style.transform = `perspective(900px) rotateX(${-offsetY}deg) rotateY(${offsetX}deg)`;
            }

        };

        const handleMouseLeave = () => {
            card.style.transform = `perspective(900px) rotateX(0deg) rotateY(0deg)`;
        };

        if (card) {
            card.addEventListener("mousemove", handleMouseMove);
            card.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (card) {
                card.removeEventListener("mousemove", handleMouseMove);
                card.removeEventListener('mouseleave', handleMouseLeave);
            }
        };

    }, []);

    return (

        <div className='section-wraper1 mb-8'>
            <div className='flex flex-col space-y-10'>
                <div onMouseMove={handleMouseMove} ref={cardRef} className='card ai-card flex lg:flex-row flex-col-reverse justify-between items-center w-full group relative rounded-xl border border-white/10 bg-[#151515]'>

                    <div className='lg:w-1/2 w-full flex justify-center lg:pt-16 pt-5  px-5 lg:relative lg:top-8'>
                        <img src={chatbot} className='sm:w-[416px] sm:h-[278px]' alt='recommandation engine'></img>
                    </div>

                    <div className='flex flex-col space-y-8 w-full lg:w-1/2 px-5 lg:px-10 py-6 lg:py-10'>
                        <div className="flex flex-col">
                            <div className='ai-card-head lg:mb-3 text-left'>Chatbot</div>
                            <p className='text-justify lg:text-left ai-card-para lg:mt-0 mt-1'>  The chatbot is an AI program that interacts with users using natural language. It provides information, answers questions, and engages in conversations to simulate human-like interactions.</p>
                        </div>
                        <Link to='/chatbot' className='try-now flex items-center space-x-3 w-fit mx-auto lg:mx-0'>
                            <span>See more</span>
                            <img className='btn-arrow' src={ArrowSvg} alt="arrow button" />
                        </Link>
                    </div>

                    < motion.div className="pointer-events-none absolute -inset-px rounded-xl opacity-0 transition duration-300 group-hover:opacity-100" style={{ background: useMotionTemplate`  radial-gradient( 550px circle at ${mouseX}px ${mouseY}px, rgba(68, 255, 255, 0.08),  transparent 90%  )`, }} />
                </div>

            </div>

        </div>

        // <div className='section-wraper1 mb-12'>
        //     <div onMouseMove={handleMouseMove} ref={cardRef} className='card ai-card w-full group  relative rounded-xl border border-white/10 bg-[#1c1c1c] chatpadding'>
        //         <div className='text-white flex lg:flex-row lg:space-x-4 flex-col-reverse h-full lg:pt-16 pt-10'>
        //             <div className="w-full lg:w-1/2 h-full  px-8 lg:mt-0 mt-10 flex justify-end">
        //                 <img className='w-5/6 h-5/6' src={chatbot} alt="chatbot" />
        //             </div>

        //             <div className="w-full lg:w-1/2 lg:px-10 px-5 flex flex-col  pb-4 space-y-8 justify-center h-full">
        //                 <div className="flex flex-col">
        //                     <div className='ai-card-head mb-3 text-center lg:text-left'>Chat bot</div>
        //                     <p className='ai-card-para lg:text-left text-justify'> The chatbot is an AI program that interacts with users using natural language. It provides information, answers questions, and engages in conversations to simulate human-like interactions.</p>
        //                 </div>
        //                 <Link to='/chatbot' className='try-now flex items-center space-x-3 w-fit mx-auto lg:mx-0'>
        //                     <span>See more</span>
        //                     <img className='btn-arrow' src={ArrowSvg} alt="arrow button" />
        //                 </Link>
        //             </div>
        //         </div>
        //         < motion.div className="pointer-events-none absolute -inset-px rounded-xl opacity-0 transition duration-300 group-hover:opacity-100" style={{ background: useMotionTemplate`  radial-gradient( 550px circle at ${mouseX}px ${mouseY}px,   rgba(14, 145, 233, 0.15),  transparent 90%  )`, }} />
        //     </div>
        // </div>

    )
}


export default SectionE