import React from 'react'

function SectionC() {
  return (
    <div className='section-wrapper unleash flex flex-col items-center justify-center mx-3 mt-24'>
      <div className='unleashH'>Unleash Potential : Elevate AI</div>
      <a href='https://edayaapp.com/contact-us' target='_blank' className='contact-btn lg:mt-5 mt-7'>Contact us</a>
    </div>
  )
}

export default SectionC
