import React from 'react'
import Ai1 from '../../images/ai1.png'
import Ai2 from '../../images/ai2.png'
import Ai3 from '../../images/ai3.png'
import ArrowSvg from '../../svg/rightArrow.svg'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { Navigation, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

function Blogs() {
    return (
        <div className='section-wraper mt-20 select-none' id="blog-3">
            <h2 className='text-center'>Blogs</h2>
            {/* <div className="mt-10 grid grid-flow-row lg:grid-cols-3 md:grid-cols-2 gap-[20px]"> */}
            <Swiper className='mt-10'
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={50}

          navigation

          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2.2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
            <SwiperSlide>
                <a href='https://blogs.edayaapp.com/revolutionizing-the-dining-experience-how-ai-powered-food-recommendations-are-changing-the-game/' target='_blank' rel="noopener" className="blog-card flex flex-col p-4 rounded-md">
                    <img className='rounded' src={Ai1} alt="AI" />
                    <p className='blog-links mt-4'>Revolutionizing the dining experience: how ai powered food...</p>
                    <div className='flex justify-end items-center mt-5 space-x-2 blog-readmore mb-1'>
                        <div>Read more</div>
                        <img className='w-3 h-3 relative top-[2px]' src={ArrowSvg} alt="arrow button" />
                    </div>
                </a>
                </SwiperSlide>
                <SwiperSlide>
                <a href='https://blogs.edayaapp.com/how-an-interactive-chatbot-can-revolutionize-hotel-guest-management/' target='_blank' rel="noopener" className="blog-card flex flex-col p-4 rounded-md">
                    <img className='rounded' src={Ai2} alt="chatbot" />
                    <p className='blog-links mt-4'>How an Interactive Chatbot Can Revolutionize Hotel Guest...</p>
                    <div className='flex justify-end items-center mt-5 space-x-2 blog-readmore mb-1'>
                        <div>Read more</div>
                        <img className='w-3 h-3 relative top-[2px]' src={ArrowSvg} alt="arrow button" />
                    </div>
                </a>
                </SwiperSlide>
                <SwiperSlide>

                <a href='https://blogs.edayaapp.com/artificial-intelligence-in-hospitality-industry/' target='_blank' rel="noopener" className="blog-card flex flex-col p-4 rounded-md">
                    <img className='rounded' src={Ai3} alt="hospitality" />
                    <p className='blog-links mt-4 w-11/12'>Artificial indelligence in hospitality industry</p>
                    <div className='flex justify-end items-center mt-5 space-x-2 blog-readmore mb-1'>
                        <div>Read more</div>
                        <img className='w-3 h-3 relative top-[2px]' src={ArrowSvg} alt="arrow button" />
                    </div>
                </a>
                </SwiperSlide>
                </Swiper>
            {/* </div> */}
        </div>
    )
}

export default Blogs