import React from 'react'
import Summerizer from './Summerizer'
import TextSummary from './TextSummary'
import TopNavA from '../TopNavA'
import Footer from '../Footer'

function TextSum() {
    return (
        <div>
            <TopNavA />
            <div className='section-wraper'>
                <Summerizer />
                <TextSummary />
            </div>
            <Footer />

        </div>
    )
}

export default TextSum