import React, { useEffect } from 'react'
import RecImg from './../images/rec.png'
import Recomm from './../images/recomm.png'
import TopNavA from './TopNavA';
import Footer from './Footer'

function Recommedation() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className='relative w-full'>
            <TopNavA />
            <div className='section-wraper select-none'>
                <div className='mt-[90px] pb-[50px]'>
                    <div className='recom-head text-[40px]>'>Recommendation Engine</div>
                    <h3 className='pt-8'>Introduction</h3>
                    <p className='pt-6'>In today's digital age, personalized recommendations have become a game-changer in various industries. At Edaya AI, we're proud to present our advanced Recommender Systems, designed to elevate user experiences to new heights. Powered by cutting-edge machine learning algorithms, our systems analyze user behavior, preferences, and past interactions to deliver tailored recommendations. In this blog post, we'll delve into the workings of Edaya's Recommender Systems and highlight the benefits they bring to both hotels and guests on the Edayapp platform.</p>
                    <div className='recom-banner mt-12 py-20 relative'>
                        <img className='sm:w-[329px] sm:h-[352px] z-10' src={RecImg} alt="" />
                        <div className="absolute bottom-20 rec-bg w-5/6 h-3/6 bg-red-300"></div>
                    </div>
                    <h3 className='pt-12'>Understanding Recommender Systems</h3>
                    <p className='pt-6'>Recommender systems are intelligent algorithms that suggest items based on a user's historical data and preferences. At Edaya AI, we've harnessed the power of these systems to transform the way users discover food items on our platform. By utilizing a technique called content-based filtering, our recommender system predicts and recommends new but similar food items to users.</p>
                    <div className='recom-banner mt-12 py-20 relative'>
                        <img className='sm:w-[553.567px] sm:h-[400.751px] z-10' src={Recomm} alt="" />
                    </div>
                    <h3 className='pt-12'>How Edaya Recommender Systems Work</h3>
                    <p className='pt-6'>Our Recommender System employs a sophisticated approach to understand and cater to individual tastes. By analyzing user clicks, ratings, and past orders, we create comprehensive user profiles. These profiles enable our system to deliver accurate and personalized recommendations that resonate with each user's unique preferences. The more a customer engages with the platform, the more refined and precise the recommendations become</p>
                    <h3 className='pt-12'>Benefits for Hotels and Guests on Edayapp</h3>
                    <p className='mt-2'>Delightful Exploration for Users:</p>
                    <ol className='ml-2 mt-2'>
                        <li className='flex space-x-2'><p className='w-1 h-1 shrink-0 bg-white rounded-full mt-3'></p><p> Users can embark on a culinary adventure, discovering new and enticing food items recommended specifically to suit their tastes.</p></li>
                        <li className='flex space-x-2 mt-2'><p className='w-1 h-1 shrink-0 bg-white rounded-full mt-3'></p><p>Personalized experiences foster user satisfaction and keep them engaged on the Edayapp platform.</p></li>
                    </ol>
                    <p className='mt-2'>Increased Sales and Customer Insights for Hotels:</p>
                    <ol className='ml-2 mt-2'>
                        <li className='flex space-x-2'><p className='w-1 h-1 shrink-0 bg-white rounded-full mt-3'></p><p>Our Recommender System serves as a powerful tool for hotels, driving increased sales of food items.</p></li>
                        <li className='flex space-x-2 mt-2'><p className='w-1 h-1 shrink-0 bg-white rounded-full mt-3'></p><p>By identifying the most frequently ordered food items, hotels gain valuable insights into customer preferences and can make data-driven decisions to optimize their offerings.</p></li>
                    </ol>
                    <h3 className='mt-12'>Conclusion</h3>
                    <p className='mt-6'>At Edaya AI, we understand the importance of tailored recommendations in creating exceptional user experiences. Our advanced Recommender Systems harness the capabilities of machine learning to revolutionize how users discover and enjoy food items on the Edayapp platform. Through personalized recommendations, we aim to satisfy the cravings of our users while empowering hotels with valuable insights into customer behavior. Join Edaya's community today and unlock the full potential of our recommender systems. Embark on a culinary journey like never before!</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Recommedation

