import React from 'react'
import ChatDashboard from '../../images/chat-bot-page.png'
import SentimentalAnalysis from '../../images/SentimentalAnalysis.png'
import textSummerizer from '../../images/textSummerizer.png'
import { Link } from 'react-router-dom'
function LandingCard(props) {
    return (
        <div className="lg:mt-20 mt-14 flex lg:flex-row-reverse flex-col justify-center  mx-auto">
            <div className="lg:w-[55%] mx-auto lg:ml-20 flex justify-end">
                <img className='sm:w-[610.5px] sm:h-[433px] sm:max-w-none' src={props.image} alt="cardbg2" />
            </div>
            <div className={`flex flex-col justify-center lg:mt-0 mt-6 w-[${props.width}%]`}>
                <h3 className='lg:text-left w-auto lg:w-[11/12] text-black'>{props.heading}</h3>
                <p className='section-para lg:text-left md:mt-6 mt-3 w-auto lg:w-12/12'>{props.para}</p>
                <Link to={props.link} className='see-more-btn mt-4 flex space-x-[10px] lg:justify-start justify-center  items-center cursor-pointer'>
                    <div className='learn'>see more</div>
                    <svg className='see-more-icon' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M9.2293 21.6717L20.3452 10.5567H12.1125C11.9063 10.5567 11.7085 10.4748 11.5626 10.329C11.4168 10.1831 11.3349 9.98532 11.3349 9.77907C11.3349 9.57282 11.4168 9.37501 11.5626 9.22917C11.7085 9.08333 11.9063 9.0014 12.1125 9.0014H22.2223C22.4286 9.0014 22.6264 9.08333 22.7722 9.22917C22.9181 9.37501 23 9.57282 23 9.77907V19.8889C23 20.0951 22.9181 20.2929 22.7722 20.4388C22.6264 20.5846 22.4286 20.6665 22.2223 20.6665C22.0161 20.6665 21.8183 20.5846 21.6724 20.4388C21.5266 20.2929 21.4446 20.0951 21.4446 19.8889V11.6562L10.3297 22.7721C10.2575 22.8444 10.1717 22.9017 10.0773 22.9408C9.98287 22.9799 9.88169 23 9.7795 23C9.67732 23 9.57614 22.9799 9.48174 22.9408C9.38733 22.9017 9.30155 22.8444 9.2293 22.7721C9.15704 22.6998 9.09973 22.6141 9.06063 22.5197C9.02152 22.4253 9.0014 22.3241 9.0014 22.2219C9.0014 22.1197 9.02152 22.0185 9.06063 21.9241C9.09973 21.8297 9.15704 21.7439 9.2293 21.6717Z" fill="url(#paint0_linear_1914_2882)" />
                        <defs>
                            <linearGradient id="paint0_linear_1914_2882" x1="16.0007" y1="23" x2="16.0007" y2="9.0014" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4384D1" />
                                <stop offset="1" stop-color="#933EFF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </Link>
            </div>
        </div>
    )
}

export default LandingCard