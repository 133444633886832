import React, {useEffect} from 'react'
import Footer from '../Footer';
import ImgChatbot from '../../images/chat-main.png'
import chat1 from '../../images/chat-1.png'
import chat2 from '../../images/chat-2.png'
import chat3 from '../../images/chat-3.png'
import chat4 from '../../images/chat-4.png'
import TopNavA from '../TopNavA';
import chatbotAll from '../../images/chatbotAll.png'
 
function Chatbot() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <div>
            <TopNavA />
            <div className='section-wraper  pt-[120px] select-none pb-[50px]'>
                <div className='font-bold text-[32px] md:text-[40px] leading-[22px]'>Edaya Chatbot</div>
                <h3 className='mt-12'>What is Edaya AI Chatbot?</h3>
                <p className='mt-6'>Edaya AI Chatbot represents a groundbreaking solution in customer support. It is an advanced automated computer program designed to simulate human conversation and provide prompt resolutions to customer queries.</p>
                <div className='chat-banner mt-12 lg:h-[450px] flex items-end px-4 lg:px-0'>
                    <div className="w-[600px] h-full relative lg:block hidden">
                        <img src={ImgChatbot} alt="chatbot" className="absolute bottom-0 left-1/2 -translate-x-1/2" />
                        <img src={chat1} alt="chat1" className="chat1 absolute w-[180px] h-[70px] left-[-50px] top-[180px]" />
                        <img src={chat2} alt="chat2" className="chat2 absolute w-[180px] h-[70px] left-[0px] top-[60px]" />
                        <img src={chat4} alt="chat4" className="chat3 absolute w-[180px] h-[50px] right-[-60px] top-[180px]" />
                        <img src={chat3} alt="chat3" className="chat4 absolute w-[200px] h-[60px] right-[0px] top-[60px]" />
                    </div>
                    <img src={chatbotAll} alt="chatbotAll" className='lg:hidden block md:w-[690px] md:h-[382px]' />
                </div>
                <h3 className='mt-12'>Experience Seamless Customer Interactions </h3>
                <p className='mt-6'>With Edaya AI Chatbot, we have redefined the way customer queries are addressed. Our chatbot leverages cutting-edge artificial intelligence technologies to engage in natural and intuitive conversations, ensuring a seamless and efficient support experience.</p>
                <h3 className='mt-12'>Key Features of Edaya AI Chatbot</h3>
                <div className='flex space-x-2 pt-8'>
                    <p>1.</p>
                    <p>Human-like Interaction: Our chatbot employs sophisticated algorithms to simulate human conversation, delivering responses that feel personalized and empathetic.</p>
                </div>
                <div className='flex space-x-2'>
                    <p>2.</p>
                    <p>Instant Query Resolution: Get quick and accurate answers to your queries without the need for human intervention, reducing wait times and improving customer satisfaction.</p>
                </div>
                <div className='flex space-x-2'>
                    <p>3.</p>
                    <p>24/7 Availability: Our chatbot is always ready to assist, providing round-the-clock support to address customer queries and concerns anytime, anywhere.</p>
                </div>
                <div className='flex space-x-2'>
                    <p>4.</p>
                    <p>Multi-channel Integration: Seamlessly integrate our chatbot across various channels, including websites, mobile apps, and social media platforms, to ensure consistent support across all touchpoints.</p>
                </div>
                <h3 className='mt-12'>The Power of Edaya AI Chatbot</h3>
                <p className='mt-6'>By harnessing the power of Edaya AI Chatbot, businesses can streamline their customer support operations and provide real-time assistance to their valued customers. Our chatbot not only reduces response times but also ensures consistent and accurate information delivery, leading to improved customer satisfaction and loyalty.</p>
                <h3 className='mt-12'>Join the Future of Customer Support</h3>
                <p className='mt-6'>Discover the power of Edaya AI Chatbot and witness the transformation it brings to your customer support ecosystem. Embrace the future of customer interactions with our advanced chatbot solution. Get in touch with us today to explore how Edaya AI Chatbot can revolutionize your customer support experience.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Chatbot
