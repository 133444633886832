import React, { useRef, useEffect, useState } from 'react'
import TopNav from '../TopNav'
import { motion, useMotionTemplate, useMotionValue } from "framer-motion";
import topGradient from '../../images/grad3.png'
import SectionB from './SectionB';
import SectionD from './SectionD';
import SectionC from './SectionC'
import Blogs from './Blogs';
import Footer from '../Footer';
import { useNavigate, } from 'react-router-dom';
import SectionE from './SectionE';
import img2 from '../../images/blur background.png'
import img3 from '../../images/secA3.png';


function Landing() {

    const toolsRef = useRef(null);
    const blogsRef = useRef(null)
    const researchRef = useRef(null)
    const hash = window.location.hash;

    const handleBlog = () => {
        const blogPosition = blogsRef.current.offsetTop - 30;
        window.scrollTo({ top: blogPosition, behavior: 'smooth' });
    }

    const handleResearch = () => {
        const researchPosition = researchRef.current.offsetTop - 310;
        window.scrollTo({ top: researchPosition, behavior: 'smooth' });
    }

    useEffect(() => {
        if (hash === '#/#blogs') {
            const blogPosition = blogsRef.current.offsetTop - 30;
            window.scrollTo({ top: blogPosition, behavior: 'smooth' });
        }
        else if (hash === '#/#research') {
            const researchPosition = researchRef.current.offsetTop - 120;
            window.scrollTo({ top: researchPosition, behavior: 'smooth' });
        }
    }, []);

    return (
        <div className='relative w-full select-none'>
            <TopNav research={handleResearch} blog={handleBlog} />
            {/* <div className="absolute section-wraper top-0 w-10/12 left-1/2 -translate-x-1/2">
                <img className='w-[87%] h-[87%]' src={topGradient} alt="Top gradient" />
            </div>
            <div className='section-wraper  md:pt-44 pt-32'>
                <h1 className='mx-auto text-center gradient-text lg:ml-[-20px]'><span className='h1span'>Experience AI Innovation: </span><br /><span className='h2span'>Shaping A Better Tomorrow</span>
                </h1>
                <p className='text-center text-sm  md:text-base font-normal md:font-normal w-full sm:w-[50%]   mx-0 sm:mx-auto mt-4 top-para'>AI innovation shapes a brighter future, revolutionizing  industries and driving transformative change.</p>

                <button onClick={handleResearch} className="glow-on-hover mx-auto top-10 -translate-x-1/2 left-1/2 w-fit"><span className='px-10 py-5'>Explore</span></button>
            </div> */}
            <div className='relative'>
            <img src={img2} alt='image1' className='absolute top-0 w-full h-full object-cover ' />
            <div className='flex flex-col justify-center items-center  md:py-[180px] py-[100px] mt-10'>
                {/* <img src={img3} alt='image3' className='absolute top-0 left-1/2 w-1/2 h-full object-cover blur' /> */}
                <h1 className='text-center lg:block hidden'>Experience AI Innovation: <br />Shaping <span className='better'> A Better Tomorrow</span></h1>
                <h1 className='text-center lg:hidden block'>Experience AI  Innovation:Shaping  <br /><span className='better'> A Better Tomorrow</span></h1>

                <p className='sm:mt-8 mt-4 secA lg:w-[40%] sm:w-[60%]'>AI innovation shapes a brighter future, revolutionizing industries and driving transformative change.</p>
                <button onClick={handleResearch} className='explore-new z-10 mt-6'>Explore</button>
            </div>
            </div>
            <div className='relative top-[200px]' ref={researchRef} > </div>
            <SectionB />
            <SectionC />
            <div ref={blogsRef}></div>
            <Blogs />
            <Footer />
        </div>

    )
}

export default Landing