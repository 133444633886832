import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Fragment } from 'react'
import { Menu } from '@headlessui/react'
import logo from './../svg/logo.svg'

function TopNav({ research, blog }) {
  const [navOpen, setNavOpen] = useState(false);
  const hash = useState(window.location.hash)

  const navigate = useNavigate();

  function toggleNav() {
    setNavOpen(!navOpen);
  }

  const toTop = () => {
    window.scrollTo(0, 0)
  }


  const clickBlog = () => {
    blog()
    navigate("/#blogs");
  }

  const clickResearch = () => {
    research()
    navigate("/#research");
  }
  const links = [
    { href: '#/text-summarization', label: 'Text summarizer' },
    { href: '#/sentimental-analysis', label: 'Sentiment recognizer' },
    { href: '#/chatbot', label: 'Chatbot' },
    { href: '#/recommendation-engine', label: 'Recommendation engine' },
  ]



  return (
    <div className='fixed top-0 z-50 w-full  ai-top-nav shadow3'>
      <div className='header-section section-wraper'>
        <NavLink to="/" onClick={toTop} className="ai-logo">
        <img src={logo} alt='logo' />
        </NavLink>
        <ul className='flex space-x-12 header-nav'>
          <li>
            <a href='https://edayaapp.com/about-us' className='nav-links text-black'>About us</a>
          </li>
          <li>
            <Menu>
              <Menu.Button className='relative'>
                <div className='flex flex-row space-x-2 items-center'>
                  <div className='nav-links'>Products</div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                    <path d="M1.90234 5L5.90215 9L9.90234 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </Menu.Button>
              <Menu.Items className='absolute top-[60px] right-[170px] drop z-60'>
                {links.map((link) => (
                  /* Use the `active` state to conditionally style the active item. */
                  <Menu.Item className='px-8 py-4'  key={link.href} as={Fragment}>
                    {({ active }) => (
                      <a
                        href={link.href} 
                        className={`${active ? 'text-[#4384D1]' : 'bg-white text-black'
                          }`}
                      >
                        {link.label}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </li>
          <li>
            <button onClick={clickBlog} className='nav-links text-black'>Blog</button>
          </li>

        </ul>
        <button onClick={toggleNav} className="cursor-pointer responsive-btn">
          {navOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="black" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1H17" stroke="black" strokeWidth="2" />
              <path d="M1 6H16" stroke="black" strokeWidth="2" />
              <path d="M3.5 11H13.5" stroke="black" strokeWidth="2" />
            </svg>

          )}
        </button>
      </div>

      <nav className={navOpen ? "resp-nav flex" : "resp-nav hidden"}>
        <ul className='w-full text-left px-5 section-wraper pb-4'>
          <li>
            <button onClick={clickResearch} className='nav-links'>About us</button>
          </li>
          <li>
            <Menu>
              <Menu.Button className='py-2'>
                <div className='flex flex-row space-x-2 items-center'>
                  <div className='nav-links'>Products</div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                    <path d="M1.90234 5L5.90215 9L9.90234 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </Menu.Button>
              <Menu.Items className='text-sm pl-2 py-2 bg-[#F1F1F1]'>
                {links.map((link) => (
                  /* Use the `active` state to conditionally style the active item. */
                  <Menu.Item className='py-2' key={link.href} as={Fragment}>
                    {({ active }) => (
                      <a
                        href={link.href}
                        className={`${active ? 'text-[#4384D1]' : 'text-black'
                          }`}
                      >
                        {link.label}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </li>
          <li>
            <button onClick={clickBlog} className='nav-links'>Blog</button>
          </li>

        </ul>
      </nav>
    </div >
  )
}

export default TopNav
