import React from 'react';

function TextSummary() {
    return (
        <div className='mt-[72px] pb-[50px] select-none'>
            <h3>What is a Text Summarizer?</h3>
            <p className='pt-6'>A Text Summarizer is a powerful system designed to condense paragraphs or lengthy texts into concise and summarized versions.</p>
            <h3 className='pt-12'>Experience the Power of Edaya AI's Text Summarizer</h3>
            <p className='pt-6'>Using our advanced Text Summarizer is a breeze. Follow these simple steps to get started:</p>
            <div className='flex space-x-2'>
                <p>1.</p>
                <p>Insert the text you wish to summarize into the designated text area.</p>
            </div>
            <div className='flex space-x-2'>
                <p>2.</p>
                <p>Click the "Summarize" button, and within seconds, you'll receive a condensed version of your input.</p>
            </div>
            <div className='pt-12 text-[28px] font-semibold'>Key Features of Edaya AI's Text Summarizer</div>
            <div className='flex space-x-2 pt-6'>
                <p>1.</p>
                <p>Free of Charge: Enjoy the full capabilities of our Text Summarizer without any cost. </p>
            </div>
            <div className='flex space-x-2'>
                <p>2.</p>
                <p>Easy Copy-Paste Functionality: Effortlessly copy the summarized text with a single click, saving you time and hassle.</p>
            </div>
            <div className='flex space-x-2'>
                <p>3.</p>
                <p>Handles Lengthy Texts: Our system can summarize input sentences of up to 600 words, allowing you to condense even the most complex texts efficiently.</p>
            </div>
            <p>Join thousands of users who have experienced the convenience and efficiency of our Text Summarizer. Try it today and simplify your reading experience.</p>
        </div>
    )
}

export default TextSummary