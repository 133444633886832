import React from 'react'

function skeltonOut() {
    return (
        <div>
            <div className='h-[340px]'></div>
        <div className=" absolute top-0 w-full h-full p-5">
            <div className="w-full h-4 bg-[#EFEFEF] rounded-sm animate-pulse3 mb-3"></div>
            <div className="w-full h-4 bg-[#EFEFEF] rounded-sm animate-pulse3 mb-3"></div>
            <div className="w-full h-4 bg-[#EFEFEF] rounded-sm animate-pulse3 mb-3"></div>
            <div className="w-full h-4 bg-[#EFEFEF] rounded-sm animate-pulse3 mb-3"></div>
            <div className="w-full h-4 bg-[#EFEFEF] rounded-sm animate-pulse3 mb-3"></div>
            <div className="w-full h-4 bg-[#EFEFEF] rounded-sm animate-pulse3 mb-3"></div>
            <div className="w-full h-4 bg-[#EFEFEF] rounded-sm animate-pulse3 mb-3"></div>
        </div>
        </div>
    )
}

export default skeltonOut