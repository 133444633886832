import React, { useState, useRef, useEffect, Fragment } from 'react'
import TextOutPut from './TextOutPut';
import axios from 'axios';
import SkeltonOut from '../SkeltonOut';
import deleteSvg from '../../svg/delete.svg'
import copySvg from '../../svg/copy.svg'
import pasteSvg from '../../svg/paste.svg'
import thumbsup from '../../svg/thumbsup.svg'
import thumbsdown from '../../svg/thumbsdown.svg'
import chatdots from '../../svg/chatdots.svg'
import clipboard from '../../svg/clipboard.svg'
import close from '../../svg/close.svg'
import thanks from '../../images/thanks.png'
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Dialog, Transition } from "@headlessui/react";

function Summerizer() {

    const [data, setData] = useState('')
    const [wordCount, setWordCount] = useState(0)
    const [outCount, setOutCount] = useState(0)
    const [outPut, setOutput] = useState('')
    const [loading, setlLoading] = useState(false)
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [numError, setNumError] = useState(false)
    const [lengthError, setLengthError] = useState(false)
    const [textmsg, setTextmsg] = useState(false)
    const pattern = /^\d+$/;
    const myDivRef = useRef(null);
    const pasteButtonRef = useRef(null);
    const inputRef = useRef(null);
    const [like, setLike] = useState(0);
    const [feedback, setFeedback] = useState('');
    let [isOpen, setIsOpen] = useState(false);
    let [thanksOpen, setThanksOpen] = useState(false);
    const [feedbackError, setFeedbackError] = useState(0)
    const [pasteError, setPasteError] = useState(false);
    const [serverError, setServerError] = useState(false)
    const [feedbackServer, setfeedbackServer] = useState(false)
    function closeModal() {
        setIsOpen(false);
        setFeedback('')
        setLike(0)
        setFeedbackError(0)
        setfeedbackServer(0)
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeThanksModal() {
        setThanksOpen(false);
    }



    const handleLikeChange = (e) => {
        e.preventDefault()
        setLike(1);
        setFeedbackError(0)
    }

    const handleDislikeChange = (e) => {
        e.preventDefault()
        setLike(2);
        setFeedbackError(0)
    }

    const handleSetFeedback = (event) => {
        setFeedback(event.target.value);
        setFeedbackError(0)
        // console.log(event.target.value.length);
        if (event.target.value.length > 60) {
            setFeedbackError(2)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(like);
        // console.log(feedback);
        const feedbackVal = JSON.stringify({
            "liked": like,
            "service": 'Text summarizer',
            "comments": feedback,
        });
        const API_URL = 'https://22anls39ag.execute-api.us-east-1.amazonaws.com/feedback';
        if (feedback == '' && like == 0) {
            setFeedbackError(1)
        }
        else {
            setFeedbackLoading(true)
            try {
                const response = await axios.post(API_URL, feedbackVal, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                console.log(response);
                if (response.data.success == true) {
                    setIsOpen(false)
                    setThanksOpen(true)
                    setFeedback('')
                    setLike(0)
                    setFeedbackError(0)
                }
                setFeedbackLoading(false)

            }
            catch (error) {
                setfeedbackServer(true)
                setFeedbackLoading(false)
                console.error(error);
            }
        }

    }

    const handleChange = (event) => {
        setData((event.target.value));
        const words = event.target.value.trim().split(' ');
        const count = words.filter(word => word !== '').length;
        setWordCount(count);
        setLengthError(false)
        setNumError(false)

    }

    const clearText = () => {
        setData('')
        setOutput('')
        setLengthError(false)
        setNumError(false)
        setWordCount(0)
        setOutCount(0)
    }

    const submitVal = async (event) => {
        event.preventDefault();

        if ((wordCount >= 50 && wordCount <= 600) && (!(data.trim().split(' ').every(word => pattern.test(word))))) {

            const dataVal = JSON.stringify({
                "text": data.replace(/\n/g, ''),
            });
            const targetPosition = myDivRef.current.offsetTop - 200;
            window.scrollTo({ top: targetPosition, behavior: 'smooth' });

            const API_URL = 'https://esas.inaipi.ae/inaipi_summarizer/summarize';
            try {
                setlLoading(true)
                const response = await axios.post(API_URL, dataVal, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                console.log(response);
                setOutput(response.data.summary);
                setlLoading(false)
                const OutPutwords = response.data.summary.trim().split(' ');
                const OutPutCount = OutPutwords.filter(word => word !== '').length;
                setOutCount(OutPutCount);


            } catch (error) {
                console.log(error);
                setServerError(true)
                setlLoading(false)
            }
        }
        if (!(wordCount >= 50 && wordCount <= 600)) {
            setLengthError(true)
        }

        if (data.trim().split(' ').every(word => pattern.test(word))) {
            setNumError(true)
            console.log(pattern.test(data));
        }
    };


    const handleCopy = () => {
        const textArea = document.createElement('textarea');
        textArea.value = outPut;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setTextmsg(true);
        setTimeout(() => {
            setTextmsg(false);
        }, 1000);
        console.log('Text copied to clipboard');
    };


    useEffect(() => {
        const handlePaste = async (event) => {
            event.preventDefault();
            try {
                const clipboardContent = await navigator.clipboard.readText();
                setData(clipboardContent);
                const words = clipboardContent.trim().split(' ');
                const count = words.filter(word => word !== '').length;
                setWordCount(count);
                setLengthError(false)
                setNumError(false)
            } catch (error) {
                console.error('Error pasting from clipboard:', error);
                setPasteError(true);
                setTimeout(() => {
                    setPasteError(false);
                }, 3000);
            }
        };


        const pasteButton = pasteButtonRef.current;
        pasteButton.addEventListener('click', handlePaste);

        return () => {
            pasteButton.removeEventListener('click', handlePaste);
        };
    }, [data]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='text-card3  mt-[100px] mb-3'>
            <div className="text-header3 relative">Summarizer
                {/* <button onPaste={handlePaste} className="absolute top-[40%] left-1/2 -translate-y-1/2 -translate-x-1/2">
                            <img src={pasteSvg} alt="" /></button> */}
                <div className="absolute flex space-x-4 top-4 right-4">

                    {outCount > 0 ? <button onClick={handleCopy} id="copy-text" className='clear-btn flex justify-center items-center space-x-2 mt-3 sm:mt-0'>
                        <img src={copySvg} alt="copy button" />
                        <ReactTooltip
                            anchorId="copy-text"
                            place="left"
                            content="Copy text"
                        />
                        {textmsg ? <div className='text-copy'>Text copied</div> : null}
                    </button> : null}

                    {wordCount > 0 ?
                        <div onClick={clearText} id="clearTxt" className="cursor-pointer px-4 flex items-center justify-center p-3 bg-[#ffffff] rounded"><img src={deleteSvg} alt="delete button" /><ReactTooltip
                            anchorId="clearTxt"
                            place="left"
                            content="Delete all"
                            className='delete-tooltip'
                        /></div> : null}

                </div></div>

            <div className="lg:flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full relative  border-r border-[#CFCFCF]">

                    <form onSubmit={submitVal} className='relative'>
                        <textarea value={data} ref={inputRef} onChange={handleChange} className='text-area-field style-8' placeholder="Enter or paste  text and click 'Summarize' to generate a summary." name="" id="" cols="30" rows="10"></textarea>
                        <div className="error-div space-y-[1px]">
                            {lengthError ? <div className="length-error">Number of words must between 50 to 600.</div> : null}
                            {numError ? <div className="num-error">The input must include more than just numbers.</div> : null}
                            {serverError ? <div className="num-error">Sorry, Something went wrong...</div> : null}
                        </div>
                        <button ref={pasteButtonRef} className={!wordCount > 0 ? `absolute top-[40%] left-1/2 -translate-y-1/2 -translate-x-1/2` : `hidden`}>
                            <img src={pasteSvg} alt="" /></button>
                        {pasteError ? <div className='absolute top-[65%] left-1/2 -translate-y-1/2 -translate-x-1/2 font-medium text-xs sm:text-sm text-center p-2 sm:p-4 border border-[#4F4F4F] rounded-md'>Unable to paste, please paste manually</div> : null}
                        <div className="input-footer  relative top-[1px]">
                            <div className='words-span'>
                                <span>Words : </span>
                                <span>{wordCount}</span>
                            </div>
                            <div className='flex space-x-4 mt-3 sm:mt-0'>

                                <button className='analyse-btn'>Summarize</button>
                            </div>

                        </div>

                    </form>


                </div>
                <div ref={myDivRef} className="lg:w-1/2 w-full relative border-[#3a3a3a] overflow-hidden">
                    {!loading ? <TextOutPut className='mt-20' data={outPut} count={outCount} /> :
                        <SkeltonOut />}
                    <div className="input-footer mt-96   absolute bottom-0">
                        <div className='words-span'>
                            <span>Words : </span>
                            <span> {outCount}</span>
                        </div>
                        <div className='absolute flex space-x-4 top-6 right-4'>
                            {/* <button id='like-title'>
                                <img className=' p-2 rounded cursor-pointer' src={thumbsup} alt='thumbsup'></img>
                                <ReactTooltip anchorId="like-title" place="bottom" content="Like" />
                            </button>
                            <button id='dislike-title'>
                                <img className=' p-2 rounded cursor-pointer' src={thumbsdown} alt='thumbsdown'></img>
                                <ReactTooltip anchorId="dislike-title" place="bottom" content="Dislike" />
                            </button> */}
                            <button id='comment-title' type="button" onClick={openModal} className='cursor-pointer px-3 flex items-center justify-center p-2 rounded'>
                                <img src={chatdots} alt='chatdots'></img>
                                <ReactTooltip anchorId="comment-title" place="bottom" content="Comment" />
                            </button>

                        </div>





                    </div>
                </div>

            </div>

            {/* {loading ? <div className="absolute top-1/2  -translate-y-1/2 right-1/2 z-50 translate-x-1/2 w-20 h-20">
                <img className='animate-load' src={loadImg} alt="" />
            </div> : null} */}


            <Transition appear show={isOpen} as={Fragment}>

                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModal}
                >

                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >

                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-20 backdrop-filter backdrop-blur" />

                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-[#ffffff] shadow-xl rounded-2xl">
                                {/*header*/}
                                <div className="flex items-start justify-between px-5 py-0 rounded-t">
                                    <h3 className="text-3xl font-semibold">

                                    </h3>
                                    <button id='close-title'
                                        className="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={closeModal}
                                    >
                                        <img className=' p-2 bg-[#ffffff] rounded cursor-pointer' src={close} alt='close'></img>
                                        <ReactTooltip anchorId="close-title" place="bottom" className='text-[6px]' />

                                    </button>
                                </div>
                                {/*body*/}
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="relative pt-0 p-6 flex-auto justify-center text-center">
                                        <h4 className='font-bold text-[22px] leading-[30px]'>Share Feedback</h4>
                                        <p className="mt-[22px] font-medium text-[14px] leading-[19px]">How would you rate your experience?</p>
                                        <div className='mt-[18px] flex justify-center w-full space-x-4'>
                                            <button onClick={handleLikeChange} className='flex justify-center items-center space-x-2 text-sm font-medium leading-[19px] w-1/2 py-3 bg-[#2B9936] hover:bg-[#37de47] rounded-lg'>
                                                {like === 1 ? (<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.9791 5.1296C17.7988 4.92538 17.5772 4.76184 17.329 4.64984C17.0807 4.53784 16.8114 4.47995 16.5391 4.48H12.0591V3.2C12.0591 2.35131 11.7219 1.53737 11.1218 0.937258C10.5217 0.337142 9.70776 1.64082e-07 8.85906 1.64082e-07C8.74016 -8.49603e-05 8.62359 0.0329529 8.52242 0.0954093C8.42124 0.157866 8.33947 0.247271 8.28626 0.3536L5.26386 6.4H1.81906C1.47959 6.4 1.15401 6.53486 0.913966 6.7749C0.673919 7.01495 0.539063 7.34052 0.539062 7.68V14.72C0.539063 15.0595 0.673919 15.385 0.913966 15.6251C1.15401 15.8651 1.47959 16 1.81906 16H15.5791C16.0468 16.0002 16.4986 15.8296 16.8494 15.5202C17.2003 15.2109 17.4262 14.7841 17.4847 14.32L18.4447 6.64C18.4787 6.36962 18.4548 6.09509 18.3745 5.83467C18.2942 5.57425 18.1594 5.3339 17.9791 5.1296ZM1.81906 7.68H5.01906V14.72H1.81906V7.68Z" fill="white" />
                                                </svg>) : (
                                                    <svg className='w-[19px] h-[16px]' viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22.0988 6.412C21.8736 6.15672 21.5966 5.9523 21.2862 5.8123C20.9759 5.6723 20.6393 5.59993 20.2988 5.6H14.6988V4C14.6988 2.93913 14.2774 1.92172 13.5273 1.17157C12.7771 0.421428 11.7597 2.05102e-07 10.6988 2.05102e-07C10.5502 -0.0001062 10.4045 0.0411911 10.278 0.119262C10.1516 0.197332 10.0493 0.309089 9.98283 0.442L6.20483 8H1.89883C1.47448 8 1.06752 8.16857 0.767457 8.46863C0.467399 8.76869 0.298828 9.17565 0.298828 9.6V18.4C0.298828 18.8243 0.467399 19.2313 0.767457 19.5314C1.06752 19.8314 1.47448 20 1.89883 20H19.0988C19.6835 20.0002 20.2482 19.7869 20.6868 19.4003C21.1254 19.0136 21.4077 18.4801 21.4808 17.9L22.6808 8.3C22.7234 7.96202 22.6935 7.61886 22.5931 7.29334C22.4928 6.96781 22.3243 6.66737 22.0988 6.412ZM1.89883 9.6H5.89883V18.4H1.89883V9.6ZM21.0928 8.1L19.8928 17.7C19.8685 17.8934 19.7743 18.0712 19.6281 18.2001C19.482 18.329 19.2937 18.4001 19.0988 18.4H7.49883V8.989L11.1698 1.646C11.7139 1.7549 12.2035 2.04896 12.5552 2.47814C12.9069 2.90733 13.099 3.44512 13.0988 4V6.4C13.0988 6.61217 13.1831 6.81566 13.3331 6.96569C13.4832 7.11571 13.6867 7.2 13.8988 7.2H20.2988C20.4124 7.19996 20.5246 7.22408 20.6281 7.27076C20.7315 7.31745 20.8239 7.38562 20.899 7.47075C20.9741 7.55588 21.0302 7.65602 21.0637 7.76451C21.0971 7.873 21.107 7.98737 21.0928 8.1Z" fill="white" />
                                                    </svg>
                                                )
                                                }

                                                <div className='text-white'>Good</div>
                                            </button>
                                            <button onClick={handleDislikeChange} className='flex justify-center items-center space-x-2 text-sm font-medium leading-[19px] w-1/2 py-3 bg-[#992B2B] hover:bg-[#ea2828] rounded-lg'>

                                                {like === 2 ? (
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.44 10.8704C17.2598 11.0746 17.0382 11.2382 16.7899 11.3502C16.5416 11.4622 16.2724 11.5201 16 11.52H11.52V12.8C11.52 13.6487 11.1829 14.4626 10.5827 15.0627C9.98263 15.6629 9.16869 16 8.32 16C8.2011 16.0001 8.08453 15.967 7.98336 15.9046C7.88218 15.8421 7.80041 15.7527 7.7472 15.6464L4.7248 9.6H1.28C0.940523 9.6 0.61495 9.46514 0.374903 9.2251C0.134857 8.98505 0 8.65948 0 8.32V1.28C0 0.940523 0.134857 0.61495 0.374903 0.374904C0.61495 0.134857 0.940523 0 1.28 0H15.04C15.5078 -0.000160217 15.9595 0.170445 16.3104 0.479782C16.6612 0.789118 16.8871 1.21591 16.9456 1.68L17.9056 9.36C17.9396 9.63038 17.9157 9.90491 17.8354 10.1653C17.7551 10.4258 17.6204 10.6661 17.44 10.8704ZM1.28 8.32H4.48V1.28H1.28V8.32Z" fill="white" />
                                                    </svg>
                                                ) : (
                                                    <svg className='w-[20px] h-[18px]' viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M23.1808 11.7L21.9808 2.1C21.9077 1.51988 21.6254 0.986398 21.1868 0.599728C20.7482 0.213057 20.1835 -0.000200559 19.5988 1.41533e-07H2.39883C1.97448 1.41533e-07 1.56752 0.168571 1.26746 0.468629C0.967399 0.768687 0.798828 1.17565 0.798828 1.6V10.4C0.798828 10.8243 0.967399 11.2313 1.26746 11.5314C1.56752 11.8314 1.97448 12 2.39883 12H6.70483L10.4828 19.558C10.5493 19.6909 10.6516 19.8027 10.778 19.8807C10.9045 19.9588 11.0502 20.0001 11.1988 20C12.2597 20 13.2771 19.5786 14.0273 18.8284C14.7774 18.0783 15.1988 17.0609 15.1988 16V14.4H20.7988C21.1394 14.4001 21.4761 14.3277 21.7865 14.1877C22.097 14.0477 22.3741 13.8431 22.5994 13.5878C22.8247 13.3324 22.9931 13.032 23.0933 12.7065C23.1936 12.381 23.2234 12.0379 23.1808 11.7ZM6.39883 10.4H2.39883V1.6H6.39883V10.4ZM21.3988 12.529C21.3243 12.6148 21.2321 12.6834 21.1285 12.7302C21.0249 12.7769 20.9125 12.8008 20.7988 12.8H14.3988C14.1867 12.8 13.9832 12.8843 13.8331 13.0343C13.6831 13.1843 13.5988 13.3878 13.5988 13.6V16C13.599 16.5549 13.4069 17.0927 13.0552 17.5219C12.7035 17.951 12.2139 18.2451 11.6698 18.354L7.99883 11.011V1.6H19.5988C19.7937 1.59993 19.982 1.67102 20.1282 1.79991C20.2743 1.9288 20.3685 2.10663 20.3928 2.3L21.5928 11.9C21.6078 12.0126 21.5982 12.1272 21.5647 12.2358C21.5313 12.3443 21.4747 12.4444 21.3988 12.529Z" fill="white" />
                                                    </svg>
                                                )
                                                }
                                                <div  className='text-white'>Bad</div>
                                            </button>
                                        </div>
                                        <p className="mt-[22px] font-medium text-[14px] leading-[19px]">Help us improve! share your feedback on the<br></br> text summarizer.</p>
                                        <textarea onChange={handleSetFeedback} style={{ resize: 'none' }} className='mt-4 bg-[#F5F5F5] outline-none rounded-lg w-full px-2 py-3 h-[90px] style-8 placeholder:text-sm text-sm' placeholder=" Share your feedback here." ></textarea>
                                        {feedbackError == 2 && feedbackError != 1 ? <div className="absolute  bottom-0 w-full flex feedback-error">The input should contain only less then 60 charecters</div> : null}
                                        {feedbackError == 1 && feedbackError != 2 ? <div className="absolute  bottom-0 w-full flex feedback-error">Please Submit your feedback</div> : null}
                                        {feedbackServer? <div className="absolute  bottom-0 w-full flex feedback-error">Sorry, Something went wrong...</div> :null}
                                    </div>
                                    {/*footer*/}
                                    <div className="flex w-full justify-center space-x-4 p-6 pt-3 rounded-b">
                                        <button
                                            className="text-[#C1C1C1] font-medium w-1/3 py-[14px] text-sm border border-[#C1C1C1] hover:bg-[#252424] rounded mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-[#1A6BE5] text-white hover:bg-[#1555b5] font-medium text-sm leading-[19px] w-2/3 py-[14px]  rounded shadow hover:shadow-lg  mb-1 ease-linear transition-all duration-150"
                                            type="submit"

                                        >
                                            <div className='flex justify-center items-center space-x-2 relative'>
                                                <div>Submit</div>
                                                {feedbackLoading ? <div class="custom-loader absolute right-1/4"></div> : null}
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={thanksOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeThanksModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-20 backdrop-filter backdrop-blur" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-[#fefefe] shadow-xl rounded-2xl">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 pb-0 rounded-t">
                                    <h3 className="text-3xl font-semibold">

                                    </h3>
                                    <button id='close-title'
                                        className="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={closeThanksModal}
                                    >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.281 14.2198C15.3507 14.2895 15.406 14.3722 15.4437 14.4632C15.4814 14.5543 15.5008 14.6519 15.5008 14.7504C15.5008 14.849 15.4814 14.9465 15.4437 15.0376C15.406 15.1286 15.3507 15.2114 15.281 15.281C15.2114 15.3507 15.1286 15.406 15.0376 15.4437C14.9465 15.4814 14.849 15.5008 14.7504 15.5008C14.6519 15.5008 14.5543 15.4814 14.4632 15.4437C14.3722 15.406 14.2895 15.3507 14.2198 15.281L8.00042 9.06073L1.78104 15.281C1.64031 15.4218 1.44944 15.5008 1.25042 15.5008C1.05139 15.5008 0.860523 15.4218 0.719792 15.281C0.579062 15.1403 0.5 14.9494 0.5 14.7504C0.5 14.5514 0.579062 14.3605 0.719792 14.2198L6.9401 8.00042L0.719792 1.78104C0.579062 1.64031 0.5 1.44944 0.5 1.25042C0.5 1.05139 0.579062 0.860523 0.719792 0.719792C0.860523 0.579062 1.05139 0.5 1.25042 0.5C1.44944 0.5 1.64031 0.579062 1.78104 0.719792L8.00042 6.9401L14.2198 0.719792C14.3605 0.579062 14.5514 0.5 14.7504 0.5C14.9494 0.5 15.1403 0.579062 15.281 0.719792C15.4218 0.860523 15.5008 1.05139 15.5008 1.25042C15.5008 1.44944 15.4218 1.64031 15.281 1.78104L9.06073 8.00042L15.281 14.2198Z" fill="#000000" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="relative pt-0 p-6 flex-auto justify-center text-center">
                                    <img className='mt-3 w-[117px] h-[112px] mx-auto' src={thanks}></img>
                                    <h4 className='font-bold text-[24px] leading-[36px] mt-5'>Thank you for sharing your feedback!</h4>
                                    <button
                                        className="okay mt-5 text-white font-medium text-lg leading-[19px] px-8 py-4  rounded shadow hover:shadow-lg  mb-1 ease-linear transition-all duration-150"
                                        type="button" onClick={closeThanksModal}

                                    >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

        </div >
    )
}

export default Summerizer