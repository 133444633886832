import logo from './logo.svg';
import './App.css';
import { HashRouter } from "react-router-dom";
import { Route, Routes, Link, Navigate, useNavigate } from "react-router-dom";
import TextSum from './components/TextSummery/TextSum';
import Sentiment from './components/Sentimental/Sentiment';
import Recommedation from './components/Recommedation';
import Landing from './components/Landing/Landing';
import Chatbot from './components/Landing/Chatbot';
import './css/style1.css';
import './css/style2.css'
function App() {
  return (
    <HashRouter>
      <Routes  >
        <Route path="/" element={<Landing />}></Route>
        <Route path="/text-summarization" element={<TextSum />}></Route>
        <Route path="/sentimental-analysis" element={<Sentiment />}></Route>
        <Route path="/recommendation-engine" element={<Recommedation />}></Route>
        <Route path="/chatbot" element={<Chatbot />}></Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
