import React, { useState, useEffect, Fragment } from 'react';
import neutralSvg from '../../svg/neutral.svg'
import negativeSvg from '../../svg/negative.svg'
import positiveSvg from '../../svg/positive.svg'
import axios from 'axios';
import DeleteSvg from '../../svg/delete.svg'
import loadingSvg from '../../svg/loading.svg'
import thumbsup from '../../svg/thumbsup.svg'
import thumbsdown from '../../svg/thumbsdown.svg'
import chatdots from '../../svg/chatdots.svg'
import clipboard from '../../svg/clipboard.svg'
import close from '../../svg/close.svg'
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TopNavA from '../TopNavA';
import Footer from '../Footer';
import thanks from '../../images/thanks.png'
import { Dialog, Transition } from "@headlessui/react";

function Sentiment() {

    const [inputData, setInput] = useState('')
    const [showResult, setResult] = useState(false)
    const [resultDiv, setResultDiv] = useState()
    const [loading, setLoading] = useState(false)
    const [reqError, setReqError] = useState(false)
    const [numError, setNumError] = useState(false)
    const [wordCount, setWordCount] = useState(0)
    const [serverError, setserverError] = useState(false)
    const [like, setLike] = useState(0);
    const [feedback, setFeedback] = useState('');
    let [isOpen, setIsOpen] = useState(false);
    let [thanksOpen, setThanksOpen] = useState(false);
    const [feedbackError, setFeedbackError] = useState(0)
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const pattern = /^\d+$/;
    const[feedBackServer, setFeedBackServer] = useState(false)
    const handleChange = (event) => {
        // console.log(event.target.value);
        setInput(event.target.value)
        setResult(false)
        setNumError(false)
        setReqError(false)
        const words = event.target.value.trim().split(' ');
        const count = words.filter(word => word !== '').length;
        setWordCount(count);
     }



    const submitHandle = async (e) => {
        e.preventDefault();
        // console.log(inputData);

        const dataVal = JSON.stringify(
            { "review": inputData }
        );

        const API_URL = 'https://esas.inaipi.ae/predict_review';


        if (wordCount > 0 && wordCount <= 400 && !(inputData.trim().split(' ').every(word => pattern.test(word)))) {
            setLoading(true)
            try {
                const response = await axios.post(API_URL, dataVal, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                console.log(response)
                console.log(response.data.sentiment);
                if (response.data.sentiment == 'neutral') {
                    setResultDiv(
                        <div className='flex flex-col justify-center space-y-2'>
                            <img src={neutralSvg} alt="neutral" />
                            <div>Neutral</div>
                        </div>)
                }
                else if (response.data.sentiment == 'negative') {
                    setResultDiv(
                        <div className='flex flex-col justify-center space-y-2'>
                            <img src={negativeSvg} alt="negaative" />
                            <div>Negative</div>
                        </div>)
                }
                else if (response.data.sentiment == 'positive') {
                    setResultDiv(
                        <div className='flex flex-col justify-center space-y-2'>
                            <img src={positiveSvg} alt="positive" />
                            <div>Positive</div>
                        </div>)
                }
                setResult(true)
                setLoading(false)

            }

            catch (error) {
                setserverError(true)
                setLoading(false)
                console.log(error);

            }
        }
        
        if ((inputData.trim().split(' ').every(word => pattern.test(word)))) {
            setNumError(true)
            // setTimeout(() => {
            //     setNumError(false)
            // }, 2000);
        }

        if (!(wordCount > 0 && wordCount <= 400)) {
            setReqError(true)
            
            // setTimeout(() => {
            //     setReqError(false)
            // }, 2000);
        }

    }

    const clearAll = () => {
        setResult(false)
        setInput('')
        setWordCount(0)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

   

    function closeModal() {
        setIsOpen(false);
        setFeedback('')
        setLike(0)
        setFeedbackError(0)
        setFeedBackServer(false)
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeThanksModal() {
        setThanksOpen(false);
    }

    function openThanksModal() {
        setThanksOpen(true);
    }

    const handleLikeChange = (e) => {
        e.preventDefault()
        setLike(1);
        setFeedbackError(0)

    }

    const handleDislikeChange = (e) => {
        e.preventDefault()
        setLike(2);
        setFeedbackError(0)
    }

    const handleSetFeedback = (event) => {
        setFeedback(event.target.value);
        setFeedbackError(0)
        if (event.target.value.length > 60) {
            setFeedbackError(2)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(like);
        // console.log(feedback);
        const feedbackVal = JSON.stringify({
            "liked": like,
            "service": 'sentimental analysis',
            "comments": feedback,
        });
        const API_URL = 'https://22anls39ag.execute-api.us-east-1.amazonaws.com/feedback';
        if (feedback == '' && like == 0) {
            setFeedbackError(1)
        }
        else {
            setFeedbackLoading(true)
            try {
                const response = await axios.post(API_URL, feedbackVal, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                console.log(response);
                if (response.data.success == true) {
                    setIsOpen(false)
                    setThanksOpen(true)
                    setFeedback('')
                    setLike(0)
                    setFeedbackError(0)
                }
                setFeedbackLoading(false)

            }
            catch (error) {
                setFeedBackServer(true);
                setFeedbackLoading(false)
                console.error(error);
            }
        }

    }



    return (
        <div>
            <TopNavA />
            <div className='section-wraper'>
                <div className='text-card3  mt-[120px] mb-3 relative '>
                    <div className="text-header3 flex sm:justify-center justify-start pl-6 sm:pl-0 relative">Sentiment  Recognizer</div>
                    <div className='absolute right-5 top-3'>
                        <div className='flex space-x-4'>
                        {inputData.length > 0 ? <div onClick={clearAll} id="app-title" className="cursor-pointer p-2 pt-[13px] px-4 rounded"><img src={DeleteSvg} alt="delete button" /><ReactTooltip
                                anchorId="app-title"
                                place="right"
                                content="Delete all"
                            /></div> : null}
                            <button className='cursor-pointer p-2  rounded' id='comment-title' onClick={openModal}>
                                <img className='' src={chatdots} alt='chatdots'></img>
                                <ReactTooltip anchorId="comment-title" place="right" content="Comment" />
                            </button>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full border-[#3a3a3a] relative  ">
                            <form onSubmit={submitHandle}>
                                <textarea value={inputData} onChange={handleChange} className='text-area-field1' placeholder="Enter or paste  your text and press 'Analyse'" name="" id="" cols="30" rows="10"></textarea>
                                <div className='flex justify-center bg-[#F6F6F6] w-full z-10 relative bottom-0 items-center  pt-4 pb-2 space-x-4'>
                                    {/* {inputData.length > 0 ? <button onClick={() => setInput('')} className='clear-btn flex justify-center items-center space-x-2'>
                                        <div> Clear text</div>
                                        <img className='relative bottom-[2px]' src={DeleteSvg} alt="clear" />
                                    </button> : null} */}
                                    <button className='analyse-btn flex items-center'>

                                        {loading ? <div className='relative px-[18px]'> <img className='w-6 h-6 animate-spin relative z-50' src={loadingSvg} alt="loading" /><div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 opacity-10">Analysing</div></div> : <div>Analyse</div>}
                                    </button>
                                    {reqError ? <div className='senti-error rounded p-2'>Please enter some text.</div> : null}
                                    {numError ? <div className='senti-error rounded p-2'>The input must include more than just numbers.</div> : null}
                                    {serverError ? <div className='senti-error rounded p-2'>Sorry, Something went wrong..</div> : null}

                                </div>
                            </form>


                        </div>


                    </div>
                    {showResult ? <div className="w-full flex flex-col moveBottom justify-center py-5 bg-[#F6F6F6] rounded-b-3xl ">
                        <div className='flex flex-col items-center space-y-2'>

                            {resultDiv}
                        </div>

                    </div> : null}


                </div>
                <div className='mt-[72px] pb-[50px] select-none'>
                    <h3>What is a Sentiment Recognizer?</h3>
                    <p className='pt-6'>A Sentiment Recognizer is an advanced system capable of identifying sentiments from given text and categorizing them as positive, neutral, or negative. Whether it's tweets, reviews, feedback, or any other form of text, our Sentiment Recognizer provides valuable insights.</p>
                    <h3 className='pt-12'>How to Use Edaya AI's Sentiment Recognizer</h3>
                    <p className='pt-6'>Discovering sentiments with our intuitive Sentiment Recognizer is simple. Just follow these steps: </p>
                    <div className='flex space-x-2'>
                        <p>1.</p>
                        <p>Insert the text for which you want to determine sentiments into the provided text field.</p>
                    </div>
                    <div className='flex space-x-2'>
                        <p>2.</p>
                        <p>Click the "Submit" button, and within moments, you'll receive an emoji indicating the sentiment expressed in the text.</p>
                    </div>
                    <h3 className='pt-12'>Key Features of Edaya AI's Sentiment Recognizer</h3>
                    <div className='flex space-x-2 pt-6'>
                        <p>1.</p>
                        <p>Free of Charge: Enjoy the complete functionality of our Sentiment Recognizer at no cost.</p>
                    </div>
                    <div className='flex space-x-2'>
                        <p>2.</p>
                        <p>Powered by the Best AI Model: Our system utilizes state-of-the-art AI models specifically designed for accurate sentiment recognition.</p>
                    </div>
                    <div className='flex space-x-2'>
                        <p>3.</p>
                        <p>Handles Varied Feedback: From concise tweets to more extensive feedback, our Sentiment Recognizer can analyze text inputs of up to 100 words.</p>
                    </div>
                    <p>Experience the accuracy and convenience of our Sentiment Recognizer. Join the countless users who have benefited from understanding sentiments in texts. Try it today and gain valuable insights.</p>
                </div>
            </div>
            <Footer />


            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-[80] overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-20 backdrop-filter backdrop-blur" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                {/*header*/}
                                <div className="flex items-start justify-between px-5 py-0 rounded-t">
                                    <h3 className="text-3xl font-semibold">

                                    </h3>
                                    <button id='close-title'
                                        className="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={closeModal}
                                    >
                                        <img className=' p-2 rounded cursor-pointer' src={close} alt='close'></img>
                                        <ReactTooltip anchorId="close-title" place="bottom" className='text-[6px]' />

                                    </button>
                                </div>
                                {/*body*/}
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="relative pt-0 p-6 flex-auto justify-center text-center text-black">
                                        <h4 className='font-bold text-[22px] leading-[30px]'>Share Feedback</h4>
                                        <p className="mt-[22px] font-medium text-[14px] leading-[19px]">How would you rate your experience?</p>
                                        <div className='mt-[18px] flex justify-center w-full space-x-4'>
                                            <button onClick={handleLikeChange} className='flex justify-center items-center space-x-2 text-sm font-medium leading-[19px] w-full py-3 bg-[#2B9936] hover:bg-[#37de47] rounded-lg'>
                                                {like === 1 ? (<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.9791 5.1296C17.7988 4.92538 17.5772 4.76184 17.329 4.64984C17.0807 4.53784 16.8114 4.47995 16.5391 4.48H12.0591V3.2C12.0591 2.35131 11.7219 1.53737 11.1218 0.937258C10.5217 0.337142 9.70776 1.64082e-07 8.85906 1.64082e-07C8.74016 -8.49603e-05 8.62359 0.0329529 8.52242 0.0954093C8.42124 0.157866 8.33947 0.247271 8.28626 0.3536L5.26386 6.4H1.81906C1.47959 6.4 1.15401 6.53486 0.913966 6.7749C0.673919 7.01495 0.539063 7.34052 0.539062 7.68V14.72C0.539063 15.0595 0.673919 15.385 0.913966 15.6251C1.15401 15.8651 1.47959 16 1.81906 16H15.5791C16.0468 16.0002 16.4986 15.8296 16.8494 15.5202C17.2003 15.2109 17.4262 14.7841 17.4847 14.32L18.4447 6.64C18.4787 6.36962 18.4548 6.09509 18.3745 5.83467C18.2942 5.57425 18.1594 5.3339 17.9791 5.1296ZM1.81906 7.68H5.01906V14.72H1.81906V7.68Z" fill="white" />
                                                </svg>) : (
                                                    <svg className='w-[19px] h-[16px]' viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22.0988 6.412C21.8736 6.15672 21.5966 5.9523 21.2862 5.8123C20.9759 5.6723 20.6393 5.59993 20.2988 5.6H14.6988V4C14.6988 2.93913 14.2774 1.92172 13.5273 1.17157C12.7771 0.421428 11.7597 2.05102e-07 10.6988 2.05102e-07C10.5502 -0.0001062 10.4045 0.0411911 10.278 0.119262C10.1516 0.197332 10.0493 0.309089 9.98283 0.442L6.20483 8H1.89883C1.47448 8 1.06752 8.16857 0.767457 8.46863C0.467399 8.76869 0.298828 9.17565 0.298828 9.6V18.4C0.298828 18.8243 0.467399 19.2313 0.767457 19.5314C1.06752 19.8314 1.47448 20 1.89883 20H19.0988C19.6835 20.0002 20.2482 19.7869 20.6868 19.4003C21.1254 19.0136 21.4077 18.4801 21.4808 17.9L22.6808 8.3C22.7234 7.96202 22.6935 7.61886 22.5931 7.29334C22.4928 6.96781 22.3243 6.66737 22.0988 6.412ZM1.89883 9.6H5.89883V18.4H1.89883V9.6ZM21.0928 8.1L19.8928 17.7C19.8685 17.8934 19.7743 18.0712 19.6281 18.2001C19.482 18.329 19.2937 18.4001 19.0988 18.4H7.49883V8.989L11.1698 1.646C11.7139 1.7549 12.2035 2.04896 12.5552 2.47814C12.9069 2.90733 13.099 3.44512 13.0988 4V6.4C13.0988 6.61217 13.1831 6.81566 13.3331 6.96569C13.4832 7.11571 13.6867 7.2 13.8988 7.2H20.2988C20.4124 7.19996 20.5246 7.22408 20.6281 7.27076C20.7315 7.31745 20.8239 7.38562 20.899 7.47075C20.9741 7.55588 21.0302 7.65602 21.0637 7.76451C21.0971 7.873 21.107 7.98737 21.0928 8.1Z" fill="white" />
                                                    </svg>
                                                )
                                                }
                                                {/* <svg className={like ===1 ? 'fill-white' : 'fill-none'} width="23" height="20" viewBox="0 0 23 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.0988 6.412C21.8736 6.15672 21.5966 5.9523 21.2862 5.8123C20.9759 5.6723 20.6393 5.59993 20.2988 5.6H14.6988V4C14.6988 2.93913 14.2774 1.92172 13.5273 1.17157C12.7771 0.421428 11.7597 2.05102e-07 10.6988 2.05102e-07C10.5502 -0.0001062 10.4045 0.0411911 10.278 0.119262C10.1516 0.197332 10.0493 0.309089 9.98283 0.442L6.20483 8H1.89883C1.47448 8 1.06752 8.16857 0.767457 8.46863C0.467399 8.76869 0.298828 9.17565 0.298828 9.6V18.4C0.298828 18.8243 0.467399 19.2313 0.767457 19.5314C1.06752 19.8314 1.47448 20 1.89883 20H19.0988C19.6835 20.0002 20.2482 19.7869 20.6868 19.4003C21.1254 19.0136 21.4077 18.4801 21.4808 17.9L22.6808 8.3C22.7234 7.96202 22.6935 7.61886 22.5931 7.29334C22.4928 6.96781 22.3243 6.66737 22.0988 6.412ZM1.89883 9.6H5.89883V18.4H1.89883V9.6ZM21.0928 8.1L19.8928 17.7C19.8685 17.8934 19.7743 18.0712 19.6281 18.2001C19.482 18.329 19.2937 18.4001 19.0988 18.4H7.49883V8.989L11.1698 1.646C11.7139 1.7549 12.2035 2.04896 12.5552 2.47814C12.9069 2.90733 13.099 3.44512 13.0988 4V6.4C13.0988 6.61217 13.1831 6.81566 13.3331 6.96569C13.4832 7.11571 13.6867 7.2 13.8988 7.2H20.2988C20.4124 7.19996 20.5246 7.22408 20.6281 7.27076C20.7315 7.31745 20.8239 7.38562 20.899 7.47075C20.9741 7.55588 21.0302 7.65602 21.0637 7.76451C21.0971 7.873 21.107 7.98737 21.0928 8.1Z"/>
                                        </svg> */}
                                                <div className='text-white'>Good</div>
                                            </button>
                                            <button onClick={handleDislikeChange} className='flex justify-center items-center space-x-2 text-sm font-medium leading-[19px] w-full py-3 bg-[#992B2B] hover:bg-[#ea2828] rounded-lg'>

                                                {like === 2 ? (
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.44 10.8704C17.2598 11.0746 17.0382 11.2382 16.7899 11.3502C16.5416 11.4622 16.2724 11.5201 16 11.52H11.52V12.8C11.52 13.6487 11.1829 14.4626 10.5827 15.0627C9.98263 15.6629 9.16869 16 8.32 16C8.2011 16.0001 8.08453 15.967 7.98336 15.9046C7.88218 15.8421 7.80041 15.7527 7.7472 15.6464L4.7248 9.6H1.28C0.940523 9.6 0.61495 9.46514 0.374903 9.2251C0.134857 8.98505 0 8.65948 0 8.32V1.28C0 0.940523 0.134857 0.61495 0.374903 0.374904C0.61495 0.134857 0.940523 0 1.28 0H15.04C15.5078 -0.000160217 15.9595 0.170445 16.3104 0.479782C16.6612 0.789118 16.8871 1.21591 16.9456 1.68L17.9056 9.36C17.9396 9.63038 17.9157 9.90491 17.8354 10.1653C17.7551 10.4258 17.6204 10.6661 17.44 10.8704ZM1.28 8.32H4.48V1.28H1.28V8.32Z" fill="white" />
                                                    </svg>
                                                ) : (
                                                    <svg className='w-[20px] h-[18px]' viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M23.1808 11.7L21.9808 2.1C21.9077 1.51988 21.6254 0.986398 21.1868 0.599728C20.7482 0.213057 20.1835 -0.000200559 19.5988 1.41533e-07H2.39883C1.97448 1.41533e-07 1.56752 0.168571 1.26746 0.468629C0.967399 0.768687 0.798828 1.17565 0.798828 1.6V10.4C0.798828 10.8243 0.967399 11.2313 1.26746 11.5314C1.56752 11.8314 1.97448 12 2.39883 12H6.70483L10.4828 19.558C10.5493 19.6909 10.6516 19.8027 10.778 19.8807C10.9045 19.9588 11.0502 20.0001 11.1988 20C12.2597 20 13.2771 19.5786 14.0273 18.8284C14.7774 18.0783 15.1988 17.0609 15.1988 16V14.4H20.7988C21.1394 14.4001 21.4761 14.3277 21.7865 14.1877C22.097 14.0477 22.3741 13.8431 22.5994 13.5878C22.8247 13.3324 22.9931 13.032 23.0933 12.7065C23.1936 12.381 23.2234 12.0379 23.1808 11.7ZM6.39883 10.4H2.39883V1.6H6.39883V10.4ZM21.3988 12.529C21.3243 12.6148 21.2321 12.6834 21.1285 12.7302C21.0249 12.7769 20.9125 12.8008 20.7988 12.8H14.3988C14.1867 12.8 13.9832 12.8843 13.8331 13.0343C13.6831 13.1843 13.5988 13.3878 13.5988 13.6V16C13.599 16.5549 13.4069 17.0927 13.0552 17.5219C12.7035 17.951 12.2139 18.2451 11.6698 18.354L7.99883 11.011V1.6H19.5988C19.7937 1.59993 19.982 1.67102 20.1282 1.79991C20.2743 1.9288 20.3685 2.10663 20.3928 2.3L21.5928 11.9C21.6078 12.0126 21.5982 12.1272 21.5647 12.2358C21.5313 12.3443 21.4747 12.4444 21.3988 12.529Z" fill="white" />
                                                    </svg>
                                                )
                                                }
                                                <div className='text-white'>Bad</div>
                                            </button>
                                        </div>
                                        <p className="mt-[22px] font-medium text-[14px] leading-[19px]">Help us improve! share your feedback on the<br></br> sentiment recognizer</p>
                                        <textarea onChange={handleSetFeedback} style={{ resize: 'none' }} className='mt-4 bg-[#F5F5F5] rounded-lg w-full px-2 py-3 h-[90px] placeholder:text-[#404040] placeholder:text-sm text-sm style-8' placeholder=" Share your feedback here." name="" id="" cols="30" rows="10"></textarea>
                                        {feedbackError == 2 && feedbackError != 1 ? <div className="absolute  bottom-0 w-full flex feedback-error">The input should contain only less then 60 charecters</div> : null}
                                        {feedbackError == 1 && feedbackError != 2 ? <div className="absolute  bottom-0 w-full flex feedback-error">Please Submit your feedback</div> : null}
                                        {feedBackServer ? <div className="absolute  bottom-0 w-full flex feedback-error">Sorry, Something went Wrong...</div> : null}
                                    </div>
                                    {/*footer*/}
                                    <div className="flex w-full justify-center space-x-4 p-6 pt-3 rounded-b">
                                        <button
                                            className="text-[#C1C1C1] font-medium w-1/3 py-[14px] text-sm border border-[#C1C1C1] hover:bg-[#f9f7f7] rounded mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-[#1A6BE5] text-white hover:bg-[#1555b5]  font-medium text-sm leading-[19px] w-2/3 py-[14px]  rounded shadow hover:shadow-lg  mb-1 ease-linear transition-all duration-150"
                                            type="submit"

                                        >
                                            <div className='flex justify-center items-center space-x-2 relative'>
                                                <div>Submit</div>
                                                {feedbackLoading ? <div class="custom-loader absolute right-1/4"></div> : null}
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={thanksOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-[90] overflow-y-auto"
                    onClose={closeThanksModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-20 backdrop-filter backdrop-blur" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 pb-0 rounded-t">
                                    <h3 className="text-3xl font-semibold">

                                    </h3>
                                    <button id='close-title'
                                        className="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={closeThanksModal}
                                    >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.281 14.2198C15.3507 14.2895 15.406 14.3722 15.4437 14.4632C15.4814 14.5543 15.5008 14.6519 15.5008 14.7504C15.5008 14.849 15.4814 14.9465 15.4437 15.0376C15.406 15.1286 15.3507 15.2114 15.281 15.281C15.2114 15.3507 15.1286 15.406 15.0376 15.4437C14.9465 15.4814 14.849 15.5008 14.7504 15.5008C14.6519 15.5008 14.5543 15.4814 14.4632 15.4437C14.3722 15.406 14.2895 15.3507 14.2198 15.281L8.00042 9.06073L1.78104 15.281C1.64031 15.4218 1.44944 15.5008 1.25042 15.5008C1.05139 15.5008 0.860523 15.4218 0.719792 15.281C0.579062 15.1403 0.5 14.9494 0.5 14.7504C0.5 14.5514 0.579062 14.3605 0.719792 14.2198L6.9401 8.00042L0.719792 1.78104C0.579062 1.64031 0.5 1.44944 0.5 1.25042C0.5 1.05139 0.579062 0.860523 0.719792 0.719792C0.860523 0.579062 1.05139 0.5 1.25042 0.5C1.44944 0.5 1.64031 0.579062 1.78104 0.719792L8.00042 6.9401L14.2198 0.719792C14.3605 0.579062 14.5514 0.5 14.7504 0.5C14.9494 0.5 15.1403 0.579062 15.281 0.719792C15.4218 0.860523 15.5008 1.05139 15.5008 1.25042C15.5008 1.44944 15.4218 1.64031 15.281 1.78104L9.06073 8.00042L15.281 14.2198Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="relative pt-0 p-6 flex-auto justify-center text-center text-black">
                                    <img className='mt-3 w-[117px] h-[112px] mx-auto' src={thanks}></img>
                                    <h4 className='font-bold text-[24px] leading-[36px] mt-5'>Thank you for sharing your feedback!</h4>
                                    <button
                                        className="okay mt-5 text-white font-medium text-lg leading-[19px] px-8 py-4  rounded shadow hover:shadow-lg  mb-1 ease-linear transition-all duration-150"
                                        type="button" onClick={closeThanksModal}

                                    >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

        </div>
    )
}

export default Sentiment
