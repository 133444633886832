import React from 'react'

function TextOutPut({ data }) {
  return (
    <div>
      {data ?
        <div className='out-pr text-area-field rounded-br-3xl rounded-bl-3xl lg:rounded-bl-none'>
          <div className='output-scroll min-h-[150px] lg:h-[250px] overflow-y-auto pr-4'>{data}</div>
        </div> :
        <div className='output-placeholder py-[18px] px-6 h-[300px]'>Summarized text will appear here</div>}



    </div>
  )
}

export default TextOutPut