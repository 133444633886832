import React from 'react'
import ChatDashboard from '../../images/chat-bot-page.png'
import SentimentalAnalysis from '../../images/SentimentalAnalysis.png'
import textSummerizer from '../../images/textSummerizer.png'
import LandingCard from '../cards/LandingCard'

function SectionB() {
    return (
        <div className='section-wraper'>
            <div className='flex flex-col justify-center items-center'>
                <h2>How the hospitality <br/> industry utilises <span className='better'>Edaya AI</span></h2>
            </div>
            
            <LandingCard image={ChatDashboard} width={10} link='/chatbot' heading="Say hello to edaya chatbot: your helpful conversational AI companion!" para ="Discover the power of Edaya Chatbot, an approachable and intuitive conversational AI designed to assist and engage with you effortlessly. Enhance your user experience and streamline interactions with this friendly virtual companion!"/>
            <LandingCard image={textSummerizer} width={45} link='/text-summarization' heading="Summarizing chats with edaya AI " para ="Gain concise insights into your interactions with Edaya, an advanced artificial intelligence language model, showcasing the key points and highlights of your conversations for easy review and analysis."/>
            <LandingCard image={SentimentalAnalysis} width={45} link='/sentimental-analysis' heading="Sorting customer feedbacks with intelligent recognition" para ="Effortlessly evaluate customer feedback with our intelligent sentiment recognizer. Get concise snapshots of sentiments, allowing you to quickly analyze and understand the overall sentiment of your customers."/>
 
        </div>
    )
}

export default SectionB
